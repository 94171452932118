@import "abstracts/_abstracts";
.ListMeasurementItem {
    position: relative;
    padding: 1rem 1.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;

    @include media('<=sm') {
        padding-block: 0.25rem;
    }

    &:before {
        content: ' ';
        display: block;
        width: 1.25rem;
        min-width: 1.25rem;
        height: 2px;
        background: var(--colorBrand);
    }

    a {
        color: var(--colorText);
    }
}